import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout/layout"
import styled from "styled-components"
import Title from "../components/title/title"
import Subscribe from "../components/subscribe/subscribe"
import { Helmet } from "react-helmet"

const LatestNews = styled.div`
    width: 80%;
    max-width: 1080px;
    margin: 0 auto;
    display: block;
  @media only screen and (min-width: 900px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px 30px;
    padding: 30px 0;
  }
`;

const NewsCard = styled.div`
    background-color: var(--neutral-100);
    box-shadow: var(--shadow-b);
    height: 100%;
    margin: 50px 0;
    @media only screen and (min-width: 900px) {
    margin: 0;
    .newsImg {
      height: 205px;
      width: auto;
    }
  }
`;

const NewsCardText = styled.div`
    height: calc(100% - 205px);
    padding: 0 15px 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Exerpt = styled.p`
 font-size: 18px;
 color: var(--neutral-600);
`;

const NewsLink = styled(Link)`
  width: fit-content;
  color: var(--accent-turquoise-600);
`;

const NewsTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
`;

const Pagination = styled.div`
    text-align: center;
    a {
        margin: 0 10px;
    }
`;

export default class News extends React.Component {
    render() {
        const articles = this.props.data.allMarkdownRemark.edges
        const { numPages } = this.props.pageContext

        return (
            <Layout>
                <Helmet>
                    <meta charSet="UTF-8" />
                    <title>News | Caplin Solar</title>
                    <html lang="en"></html>
                </Helmet>
                <Title title="Latest news" subtitle="All the most recent developments and updates from the Caplin Solar team." />    
                <section>
                    <LatestNews>
                        {articles.map(({ node }) => (
                        <NewsCard key={node.id}>
                            <Img className="newsImg" fluid={node.frontmatter.featuredImage.childImageSharp.fluid} />
                            <NewsCardText>
                                <NewsTitle>{node.frontmatter.title}</NewsTitle>
                                <Exerpt>{node.excerpt}</Exerpt>
                                <NewsLink to={node.fields.slug}>Read article</NewsLink>
                            </NewsCardText>
                        </NewsCard>
                        ))}
                    </LatestNews>
                    <Pagination style={{display: numPages > 1 ? 'block' : 'none'}}>
                        {Array.from({ length: numPages }, (_, i) => (
                            <Link key={`pagination-number${i + 1}`} to={`/news/${i === 0 ? "" : i + 1}`}>
                                {i + 1}
                            </Link>
                        ))}
                    </Pagination>
                </section>
                <Subscribe />
            </Layout>
        )
    }
}

export const articleListQuery = graphql`
    query articleListQuery($skip: Int!, $limit: Int!) {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        date(formatString: "DD MMMM YYYY")
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 720, quality: 72) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                    excerpt
                }
            }
        }
    }
`